import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { AsxImage, IconType, PrimaryButton } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './TeaserCardImage.module.scss';

export interface TeaserCardImageProps {
  cardTitle?: string;
  cta?: string;
  linkUrl?: string;
  image?: string;
  alt?: string;
  icon?: IconType;
}

export const TeaserCardImage: FunctionComponent<TeaserCardImageProps> = ({
  cardTitle: heading,
  linkUrl,
  image,
  cta,
  alt,
  icon = 'chevronRight',
}) => (
  <div className={styles.TeaserCardImage}>
    <AsxImage src={image} alt={alt} className={styles.image} componentName="TeaserCardImage" />
    <div className={styles.contentWrapper}>
      <h2 className={styles.cardTitle}>{heading}</h2>
      <hr className={styles.divider} aria-hidden />
      <div className={styles.ctaWrapper}>
        {linkUrl && (
          <Link className={styles.cta} to={linkUrl}>
            {cta}
          </Link>
        )}
        <PrimaryButton icon={icon} link={linkUrl} theme={SECTION_THEME.Dark} />
      </div>
    </div>
  </div>
);
