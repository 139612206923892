import { useEffect, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { AccordionCards } from 'src/components/atoms/accordion-cards/AccordionCards';
import { BentoCards } from 'src/components/atoms/bento-cards/BentoCards';
import { CardsDashboard } from 'src/components/atoms/cards-dashboard/CardsDashboard';
import { HeroAsset } from 'src/components/atoms/hero-asset/HeroAsset';
import { NavigationTabs } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import { AnimatedHeroBanner, PageSection, QuoteComponent, CustomGraphic, TextAndCard } from 'src/components/common';
import { quoteMock } from 'src/components/common/quote-component/quoteComponent.mock';
import { FOCUS_AREA_URL } from 'src/components/pages/resource-pages/focus-areas/focusAreaPath';
import { CREDITS_BUNDLE_NAME } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import fallbackImage1 from 'src/images/fallback/fallback-image-1.jpg';
import fallbackImage2 from 'src/images/fallback/fallback-image-2.jpg';
import fallbackImage3 from 'src/images/fallback/fallback-image-3.png';
import carbonRemovalAnimationDesktop from 'src/lotties/carbon-removal-desktop.json';
import carbonRemovalAnimationMobile from 'src/lotties/carbon-removal-mobile.json';

import styles from './ProjectDeborah.module.scss';

// TODO: Replace all mocked assets with original ones SIM: https://issues.amazon.com/issues/ASX-332

const tabsMock = [
  {
    image: { src: fallbackImage1, alt: 'alt text image 1' },
    label: 'Lorem ipsum dolor sit 1',
  },
  {
    image: { src: fallbackImage2, alt: 'alt text image 2' },
    label: 'Lorem ipsum dolor sit 2',
  },
  {
    image: { src: fallbackImage3, alt: 'alt text image 3' },
    label: 'Lorem ipsum dolor sit 3',
  },
];

const dashboardCardsMcok = {
  firstCard: {
    src: fallbackImage1,
    alt: 'alt text frist card',
  },
  secondCard: {
    label: 'Current state',
    title: 'Largest emitters',
    description:
      'If deforestation were a country, it would be the third largest emitter behind China and the United States.',
  },
  thirdCard: {
    label: 'Label',
    title: 'Lorem ipsum',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet',
  },
  fourthCard: {
    src: fallbackImage3,
    alt: 'alt text third card',
  },
};

const heroAssetMock = {
  title: 'Lorem ipsum dolor sit amet',
  image: { src: fallbackImage3, alt: 'Hero asset alt text' },
};

const accordionCardsMock = {
  ariaLabel: 'Lorem ipsum',
  items: [
    {
      title: 'Lorem ipsum',
      text: 'Lorem ipsum dolor sit amet consectetur. Orci mattis ullamcor per felis fermentum molestie nulla. Faucibus nulla amet erat vivamus amet nisi augue vel. Vel varius varius nibh vulputate augue. Lorem ipsum dolor sit amet consectetur. Orci mat.',
    },
    {
      title: 'Lorem ipsum',
      text: 'Orci mattis ullamcor per felis fermentum molestie nulla. Faucibus nulla amet erat vivamus amet nisi augue vel. Vel varius varius nibh vulputate augue. Lorem ipsum dolor sit amet consectetur. Orci mat.',
    },
    {
      title: 'Lorem ipsum',
      text: 'Faucibus nulla amet erat vivamus amet nisi augue vel. Vel varius varius nibh vulputate augue. Lorem ipsum dolor sit amet consectetur. Orci mat.',
    },
    {
      title: 'Lorem ipsum',
      text: 'Vel varius varius nibh vulputate augue. Lorem ipsum dolor sit amet consectetur. Orci mat.',
    },
    {
      title: 'Lorem ipsum',
      text: 'Vivamus amet nisi augue vel. Vel varius varius nibh vulputate augue. Lorem ipsum dolor sit amet consectetur. Orci mat.',
    },
    {
      title: 'Lorem ipsum',
      text: 'Mattis ullamcor per felis fermentum molestie nulla. Faucibus nulla amet erat vivamus amet nisi augue vel. Vel varius varius nibh vulputate augue. Lorem ipsum dolor sit amet consectetur. Orci mat.',
    },
  ],
};

const bentoCardsMock = {
  label: 'Label',
  title: 'Lorem ipsum dolor sit amet consectetur id eget ipsumo',
  description: `Lorem ipsum dolor sit amet consectetur. Vel *tempor* iaculis at dic tum lorem dis. Ac diam turpis semper ornare. Dictumst maece nas duis diam vestibulum ullamcorper. In iaculis fermentum ele men tum eget suspendisse laoreet arcu suspendisse quam. Eu nec at *orci* mauris. Netus molestie est nibh.
  
  Lorem nec facilisi pellentesque in nisl nisl sit mollis amet. Pellentesque blandit enim pretium est proin ut odio. Integer nam aenean natoque facilisi. Nunc amet enim dolor accumsan. Egestas nec viverra porttitor eu. Nec dictum mattis massa pretium. Id at nulla id a tortor mi at.`,
  tooltipAttributes: [
    {
      image: { src: fallbackImage1, alt: 'alt text tooltip image' },
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit sem bibendum turpis sollicitudin.',
      cta: {
        label: 'Label',
        url: '#',
      },
    },
    {
      image: { src: fallbackImage1, alt: 'alt text tooltip image' },
      description: 'Second tooltip Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      cta: {
        label: 'Label',
        url: '#',
      },
    },
  ],
  tooltipTheme: 'dark' as SECTION_THEME,
  firstCard: {
    label: 'Label',
    title: 'Lorem ipsum dolo sietame',
  },
  secondCard: {
    label: 'Label',
    title: 'Lorem ipsum dolo sietame',
  },
};

export const ProjectDeborah = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [creditsBundle] = useBundle(CREDITS_BUNDLE_NAME);
  const { isDesktop } = useDeviceTracker();

  // This resets our coordinates on page load to make sure the banner animation will be visible instead of the page
  // going back to the previous scroll position
  useEffect(() => {
    // Disable native scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Scroll to top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AnimatedHeroBanner
        title={t(creditsBundle, 'creditsPage_heroBanner_title')}
        subtitle={t(creditsBundle, 'creditsPage_heroBanner_subtitle')}
        ctaLabel={t(creditsBundle, 'creditsPage_heroBanner_ctaLabel')}
        videoHeadline={t(creditsBundle, 'creditsPage_heroBanner_video_headline')}
        previewInformation={t(creditsBundle, 'creditsPage_heroBanner_video_previewInformation')}
        outro={{
          title: t(creditsBundle, 'creditsPage_heroBanner_outro_title'),
          label: t(creditsBundle, 'creditsPage_heroBanner_outro_label'),
          linkText: t(creditsBundle, 'creditsPage_heroBanner_outro_linkText'),
          textContent: t(creditsBundle, 'creditsPage_heroBanner_outro_textContent'),
          modalContent: {
            tag: t(creditsBundle, 'creditsPage_heroBanner_outro_modal_tag'),
            quote: t(creditsBundle, 'creditsPage_heroBanner_outro_modal_quote'),
            source: {
              text: t(creditsBundle, 'creditsPage_heroBanner_outro_modal_linkText'),
              link: 'https://www.ipcc.ch/sr15/chapter/spm/',
            },
          },
        }}
      />

      <TextAndCard
        title={t(creditsBundle, 'creditsPage_textAndCard_title')}
        subTitle={t(creditsBundle, 'creditsPage_textAndCard_subtitle')}
        link={{
          label: t(creditsBundle, 'creditsPage_textAndCard_link_label'),
          href: `${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.CarbonNeutralization}`,
          icon: 'download',
        }}
        card={{
          title: t(creditsBundle, 'creditsPage_textAndCard_card_title'),
          copy: t(creditsBundle, 'creditsPage_textAndCard_card_copy'),
          list: [
            t(creditsBundle, 'creditsPage_textAndCard_card_list_item_1'),
            t(creditsBundle, 'creditsPage_textAndCard_card_list_item_2'),
            t(creditsBundle, 'creditsPage_textAndCard_card_list_item_3'),
          ],
          ctas: {
            primaryLabel: t(creditsBundle, 'creditsPage_textAndCard_card_cta_1_label'),
            secondaryLabel: t(creditsBundle, 'creditsPage_textAndCard_card_cta_2_label'),
          },
        }}
        bigImage={{ src: fallbackImage1, alt: t(creditsBundle, 'creditsPage_textAndCard_big_image_alt') }}
        smallImage={{ src: fallbackImage2, alt: t(creditsBundle, 'creditsPage_textAndCard_small_image_alt') }}
      />
      <CustomGraphic
        tagline={t(creditsBundle, 'creditsPage_customGraphic_tagline')}
        heading={t(creditsBundle, 'creditsPage_customGraphic_heading')}
        copy={t(creditsBundle, 'creditsPage_customGraphic_copy')}
        description={t(creditsBundle, 'creditsPage_customGraphic_description')}
        lottieAnimation={isDesktop ? carbonRemovalAnimationDesktop : carbonRemovalAnimationMobile}
        lottieAlt={t(creditsBundle, 'creditsPage_customGraphic_lottie_alt')}
        details={{
          detailFirst: t(creditsBundle, 'creditsPage_customGraphic_detail_first'),
          detailSecond: t(creditsBundle, 'creditsPage_customGraphic_detail_second'),
          detailThird: t(creditsBundle, 'creditsPage_customGraphic_detail_third'),
          detailFourth: t(creditsBundle, 'creditsPage_customGraphic_detail_fourth'),
          detailFifth: t(creditsBundle, 'creditsPage_customGraphic_detail_fifth'),
        }}
        footerContent={{
          footerParagraph: t(creditsBundle, 'creditsPage_customGraphic_footer_paragraph'),
          footerLinkLabel: t(creditsBundle, 'creditsPage_customGraphic_footer_link_label'),
          footerLinkHref: t(creditsBundle, 'creditsPage_customGraphic_footer_link_href'),
          footerCTA: t(creditsBundle, 'creditsPage_customGraphic_footer_cta'),
        }}
        modalContent={{
          modalImage: { src: fallbackImage1, alt: t(creditsBundle, 'creditsPage_customGraphic_modal_image_alt') },
          modalTitle: t(creditsBundle, 'creditsPage_customGraphic_modal_title'),
          modalCopy: t(creditsBundle, 'creditsPage_customGraphic_modal_copy'),
        }}
      />
      <PageSection>
        <CardsDashboard {...dashboardCardsMcok} />
      </PageSection>
      <PageSection theme="slate-dark" className={styles.quotePageSection}>
        <QuoteComponent {...quoteMock} />
      </PageSection>
      <NavigationTabs activeTab={activeTab} tabs={tabsMock} onTabChange={(index) => setActiveTab(index)} />
      <PageSection>
        <HeroAsset {...heroAssetMock} />
      </PageSection>
      <CardsDashboard {...dashboardCardsMcok} />
      <BentoCards {...bentoCardsMock} />
      <AccordionCards {...accordionCardsMock} />
    </>
  );
};
