import { FunctionComponent } from 'react';

import { AsxImage, Heading, HEADING_SIZE, Icon } from 'src/components/atoms';
import { BentoCard } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './CardsDashboard.module.scss';

/*
   Object - card content, displays a label, title and description and a graphic svg
  */
interface CardProp {
  /*
    Tagline that will be displayed at the top left of the card
    */
  label: string;

  /*
    Title of the card
    */
  title: string;

  /*
    Body text of the card
    */
  description: string;
}

interface CardsDashboardProps {
  /*
   Object - first card content, is a simple image
  */
  firstCard: ImageAttributes;

  /*
   Object - second card content
  */
  secondCard: CardProp;

  /*
   Object - third card content
  */
  thirdCard: CardProp;

  /*
   Object - fourth card content, is just a simple image
  */
  fourthCard: ImageAttributes;
}

export const CardsDashboard: FunctionComponent<CardsDashboardProps> = ({
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
}) => (
  <div className={styles.cardsDashboard}>
    <AsxImage className={styles.card} {...firstCard} componentName="CardsDashboard" />
    <BentoCard theme={SECTION_THEME.Dark} className={styles.card} label={secondCard.label}>
      <Heading className={styles.cardHeading} as="h3" size={HEADING_SIZE.h3}>
        {secondCard.title}
      </Heading>
      <p className={styles.cardDescription}>{secondCard.description}</p>
      <Icon className={styles.cardGraphic} name="cardsDashboardGraphic1" />
    </BentoCard>
    <BentoCard className={styles.card} label={thirdCard.label}>
      <div className={styles.cardContent}>
        <Heading className={styles.cardHeading} as="h3" size={HEADING_SIZE.h2}>
          {thirdCard.title}
        </Heading>

        <p className={styles.cardDescription}>{thirdCard.description}</p>
      </div>
    </BentoCard>
    <AsxImage className={styles.card} {...fourthCard} componentName="CardsDashboard" />
  </div>
);
