import { CoralRequest, CoralInterceptor } from '@amzn/ihm-js-coral-client';

const addHeaders = (request: CoralRequest): CoralRequest =>
  request.clone({ headers: [...request.headers, ['Accept', 'application/json']] });

const createHeaderInterceptor = (): CoralInterceptor => ({
  intercept(request, next) {
    return next.handle(addHeaders(request));
  },
});

export const HeaderInterceptor = createHeaderInterceptor();
