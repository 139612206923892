// ASX Image Metrics
export const IMAGE_LOAD_TIME = 'image-load-time';
export const IMAGE_LOAD_SUCCESS = 'image-load-success';
export const IMAGE_LOAD_FAILURE = 'image-load-failure';

// Translation metrics
export const TRANSLATION_STRING_FAILURE = 'translation-string-failure';
export const STRING_ID_DIMENSION = 'stringId';

// Error Boundary metrics
export const ERROR_BOUNDARY_ENCOUNTER = 'error-boundary-encounter';
export const INIT_ERROR_BOUNDARY_ENCOUNTER = 'AppInitErrorBoundary.Encounter';

// Search metrics
export const SEARCH_ERROR = 'search-error';
export const PROCESS_DIMENSION = 'process';
export const RESULT_ID_DIMENSION = 'resultId';

// Referer metrics
export const REFERER_ENCOUNTER = 'referer-encounter';
export const ORIGIN_DIMENSION = 'origin';

// SaveASX Form Metrics
export const SAVEASXFORM_ERROR = 'saveAsxForm-error';

// HMD Metrics
// Dimensions
export const HMD_METHOD_DIMENSION = 'hmdFeedback';
export const RATING_VALUE_DIMENSION = 'ratingValue';
export const RATING_COMMENT_PART_DIMENSION = 'ratingCommentPart';
export const RATING_URL_DIMENSION = 'ratingURL';
export const RATING_USER_AGENT_DIMENSION = 'ratingUserAgent';
export const RATING_SUBJECT_DIMENSION = 'ratingSubject';

// Custom Dimensions
export const INGRESS_PAGE = 'ingressPage';
export const RESOURCE_ID = 'resourceId';
export const RESOURCE_TITLE = 'resourceTitle';

// Constants
export const HMD_FEEDBACK_SUBJECT_OTHER = 'Other';

// Mons SessionID cookie
export const MONS_SESSION_ID = '__Host-mons-sid';
