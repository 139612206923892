import classNames from 'classnames';
import { ComponentProps, type ReactElement } from 'react';

import { IconType, icons, AsxImage, FormattedLink } from 'src/components/atoms';
import { RedirectionCard } from 'src/components/common';
import { SUBTITLE_CLASS } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderDeborahLandingPage } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './SecondaryHero.module.scss';

export type SecondaryHeroProps = {
  theme?: SECTION_THEME;
  copy?: string;
  heading?: string;
  link?: {
    text: string;
    url: string;
    iconName?: IconType;
  };
  image: ImageAttributes;

  /*
   * Object - Optional clickable redirection card
   */
  card?: ComponentProps<typeof RedirectionCard>;
};

export function SecondaryHero({
  copy,
  heading,
  link,
  image,
  theme = SECTION_THEME.Light,
  card,
}: SecondaryHeroProps): ReactElement {
  const { isSmallDesktop } = useDeviceSize();

  return (
    <div className={classNames(styles.SecondaryHero, styles[theme])}>
      <div className={styles.flexContent}>
        {heading && <h1 className={styles.heading}>{heading}</h1>}
        {isSmallDesktop ? (
          <div className={styles.copyContainer}>
            {copy && <p className={classNames(styles.copy, SUBTITLE_CLASS)}>{copy}</p>}
            {link && (
              <FormattedLink
                className={styles.externalLink}
                text={link.text}
                link={link.url}
                showIcon
                iconName={link.iconName ? link.iconName : (icons.chevronTopRight as IconType)}
                openNewTab
              />
            )}
          </div>
        ) : (
          <div className={styles.imageWrapper}>
            <AsxImage
              key={`${image.src}-${image.alt}`}
              src={image.src}
              alt={image.alt}
              componentName="SecondaryHero"
              className={styles.image}
            />
          </div>
        )}
      </div>
      {isSmallDesktop ? (
        <div className={styles.imageWrapper}>
          <AsxImage
            key={`${image.src}-${image.alt}`}
            src={image.src}
            alt={image.alt}
            className={styles.image}
            componentName="SecondaryHero"
          />
          {card && shouldRenderDeborahLandingPage() && <RedirectionCard className={styles.redirectionCard} {...card} />}
        </div>
      ) : (
        <div className={classNames(styles.copyContainer, card && styles.hasRedirectionCard)}>
          {copy && <p className={classNames(styles.copy, SUBTITLE_CLASS)}>{copy}</p>}
          {card && shouldRenderDeborahLandingPage() && <RedirectionCard className={styles.redirectionCard} {...card} />}
          {link && (
            <FormattedLink
              className={styles.externalLink}
              text={link.text}
              link={link.url}
              showIcon
              iconName={link.iconName ? link.iconName : 'chevronTopRight'}
              openNewTab
            />
          )}
        </div>
      )}
    </div>
  );
}
