import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { AsxImage } from 'src/components/atoms';
import { NavigationTabsProps } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import styles from 'src/components/atoms/navigation-tabs/NavigationTabs.module.scss';
import { useHeaderVisibility } from 'src/hooks/useHeaderVisibility';

export const NavigationTabsDesktop: FunctionComponent<NavigationTabsProps> = ({ activeTab, tabs, onTabChange }) => {
  const handleTabClick = (index: number) => onTabChange(index);
  const { isHeaderVisible } = useHeaderVisibility();

  return (
    <div className={classNames(styles.navigationTabsStickyContainer, isHeaderVisible && styles.withNavbar)}>
      <div className={styles.navigationTabs}>
        {tabs.map(({ image, label }, index) => (
          <button
            data-testid="navigation-tab"
            type="button"
            className={classNames(styles.tab, activeTab === index && styles.isActive)}
            key={label}
            onClick={() => handleTabClick(index)}
          >
            <AsxImage className={styles.image} {...image} componentName="NavigationTabsDesktop" />
            <p className={styles.label}>{label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};
