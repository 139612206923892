import { FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PageIngress } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { FormattedLink, PrimaryButtonV2 } from 'src/components/atoms';
import { ImagesBackground } from 'src/components/common/images-background/ImagesBackground';
import imageStyles from 'src/components/common/images-background/ImagesBackground.module.scss';
import { RedirectionCardProps } from 'src/components/common/redirection-card/RedirectionCard';
import { RedirectionCardList } from 'src/components/common/redirection-card-list/RedirectionCardList';
import { PAGE_INGRESS_BY_ROUTE } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import { useThankYouData } from './useThankYouData';

interface ThankYouPageProps {
  // Status of the submission, either 'success' or 'failure'.
  submissionStatus?: 'success' | 'failure';

  // Text content displayed on the page.
  copy: string;

  // Position of the copy text, either above or below the title.
  copyPosition?: 'aboveTitle' | 'underTitle';

  // Main title of the page.
  title: string;

  // Label for the primary button.
  cta: string;

  // Optional URL for the primary button if it's a link.
  ctaSrc?: string;

  // Determines if the primary button is a "Back" button.
  isBackButton?: boolean;

  // Optional link object with URL and text.
  link?: { linkSrc: string; linkCopy: string };

  // Optional prop for making title 100% width on mobile
  isFullWidthMobile?: boolean;
}

const ThankYouContainer = styled.div`
  display: flex !important;
  align-items: center;
  margin-top: 9.3vh;
  flex-direction: column;
  height: 100vh;
  color: ${variables.greenPrimary};
  background-color: ${variables.greySecondary};
  padding: 0 16px;

  @media (min-width: ${variables.largeDevice}) {
    margin-top: 0;
    justify-content: center;
  }

  @media (min-width: ${variables.smallDesktop}) {
    padding: 0 60px;
  }
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
`;

const ThankYouContent = styled.div<{ isFullWidthMobile?: boolean }>`
  text-align: center;
  width: ${({ isFullWidthMobile }) => (isFullWidthMobile ? '100%' : '80%')};
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;

  @media (min-width: ${variables.smallDevice}) {
    width: 80%;
  }

  @media (min-width: ${variables.smallDesktop}) {
    width: 65%;
    flex-basis: 65%;
    gap: 32px;
  }
`;

const Copy = styled.p`
  margin: 0 !important;
  opacity: 0.85;
  font-size: ${variables.subtitleSize} !important;
  line-height: 32px !important;
`;

const BackCta = styled(PrimaryButtonV2)`
  font-size: 16px !important;
`;

const ThankYouLink = styled(FormattedLink)`
  margin-top: 40px;
  z-index: 2;
  color: ${variables.greenPrimary} !important;
`;

export const ThankYouPage: FunctionComponent<ThankYouPageProps> = ({
  submissionStatus = 'failure',
  copy,
  copyPosition = 'underTitle',
  title,
  cta,
  link,
  ctaSrc,
  isBackButton,
  isFullWidthMobile,
}) => {
  const location = useLocation();
  const { isSmallDesktop } = useDeviceSize();
  const navigate = useNavigate();
  const pageIngress = PAGE_INGRESS_BY_ROUTE[location.pathname];
  const { images, subtitle } = useThankYouData(pageIngress, isSmallDesktop);
  const cards: RedirectionCardProps[] = [];
  const isSuccess = submissionStatus === 'success';

  useEffect(() => {
    // Scroll to top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handleCtaClick = () => {
    if (isSuccess) {
      // navigate to previous page in browser history
      navigate(-1);
    } else {
      // refresh the current route (contact form) in browser history
      navigate(0);
    }
  };

  return (
    <ThankYouContainer>
      <CopyContainer>
        <ThankYouContent isFullWidthMobile={isFullWidthMobile}>
          {copyPosition === 'aboveTitle' ? (
            <>
              <Copy>{copy}</Copy>
              <h2>{title}</h2>
            </>
          ) : (
            <>
              <h2>{title}</h2>
              <Copy>{copy}</Copy>
            </>
          )}
        </ThankYouContent>

        {isBackButton ? (
          <BackCta
            onClick={handleCtaClick}
            label={cta}
            theme={SECTION_THEME.Light}
            icon="chevronRight"
            data-testid="backCta"
          />
        ) : (
          <BackCta link={ctaSrc} label={cta} theme={SECTION_THEME.Light} icon="chevronRight" data-testid="backCta" />
        )}

        {link && <ThankYouLink text={link.linkCopy} link={link.linkSrc} iconName="chevronRight" />}
      </CopyContainer>

      {cards.length > 0 ? (
        <RedirectionCardList subtitle={subtitle} cards={cards} />
      ) : (
        <ImagesBackground
          images={images}
          className={
            pageIngress === PageIngress.PROJECT_DEBORAH_INTEREST
              ? imageStyles.creditsInterestFormImages
              : imageStyles.contactFormImages
          }
        />
      )}
    </ThankYouContainer>
  );
};
