import { useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

export const useTextAndCard = () => {
  const self = useRef(null);
  const smallImageRef = useRef(null);
  const bigImageRef = useRef(null);
  const bigImageMobileRef = useRef(null);
  const copyRef = useRef(null);
  const linkRef = useRef(null);
  const cardRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: self,
    offset: ['start end', 'end start'],
  });

  const smallImageY = useTransform(scrollYProgress, [0, 1], ['150px', '-150px']);
  const bigImageY = useTransform(scrollYProgress, [0, 1], ['100px', '-100px']);
  const bigImageYMobile = useTransform(scrollYProgress, [0, 1], ['40px', '-40px']);
  const copyY = useTransform(scrollYProgress, [0, 1], ['30px', '-30px']);
  const linkY = useTransform(scrollYProgress, [0, 1], ['30px', '-30px']);
  const cardY = useTransform(scrollYProgress, [0, 1], ['50px', '-150px']);
  const cardYMobile = useTransform(scrollYProgress, [0, 1], ['50px', '-50px']);
  const selfColor = useTransform(scrollYProgress, [0.7, 0.9], ['#232F3E', '#F2F7F7']);

  return {
    refs: { self, smallImageRef, bigImageRef, bigImageMobileRef, copyRef, linkRef, cardRef },
    parallaxStyles: {
      smallImage: { y: smallImageY },
      bigImage: { y: bigImageY },
      bigImageMobile: { y: bigImageYMobile },
      copyY: { y: copyY },
      linkY: { y: linkY },
      cardY: { y: cardY },
      selfColor: { backgroundColor: selfColor },
      cardYMobile: { y: cardYMobile },
    },
  };
};
