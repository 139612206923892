import { PageIngress } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';

import { getCreditsImageRoot } from 'src/helpers';

// TODO: Move images to content resource library
// https://sim.amazon.com/issues/ASX-227
import imageFallback1 from 'src/images/fallback/images-background/image-1.png';
import imageFallback2 from 'src/images/fallback/images-background/image-2.png';
import imageFallback3 from 'src/images/fallback/images-background/image-3.png';
import imageFallback4 from 'src/images/fallback/images-background/image-4.png';

const getFileType = (isSmallDesktop: boolean) => (isSmallDesktop ? 'jpg' : 'png');

export const useThankYouData = (pageIngress: PageIngress, isSmallDesktop: boolean) => {
  if (PageIngress.PROJECT_DEBORAH_INTEREST === pageIngress) {
    return {
      images: [
        {
          src: `${getCreditsImageRoot(isSmallDesktop)}/credits-interestform-thankyou-1.${getFileType(isSmallDesktop)}`,
          alt: 'A forest being replanted',
        },
        {
          src: `${getCreditsImageRoot(isSmallDesktop)}/credits-interestform-thankyou-2.${getFileType(isSmallDesktop)}`,
          alt: 'A rainforest',
        },
        {
          src: `${getCreditsImageRoot(isSmallDesktop)}/credits-interestform-thankyou-3.${getFileType(isSmallDesktop)}`,
          alt: 'A direct air capture module',
        },
        {
          src: `${getCreditsImageRoot(isSmallDesktop)}/credits-interestform-thankyou-4.${getFileType(isSmallDesktop)}`,
          alt: "Agricultural produce in a person's hands",
        },
      ],
      subtitle: 'Keep exploring',
    };
  }
  return {
    images: [
      { src: imageFallback1, alt: 'image top left' },
      { src: imageFallback2, alt: 'image top right' },
      { src: imageFallback3, alt: 'image bottom left' },
      { src: imageFallback4, alt: 'image bottom right' },
    ],
    subtitle: 'Keep exploring',
  };
};
