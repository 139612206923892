import Lottie from 'lottie-react';
import { FunctionComponent, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import {
  AsxImage,
  FormattedLink,
  Icon,
  PRIMARY_BUTTON_V2_VARIANT,
  PrimaryButtonV2,
  Tagline,
} from 'src/components/atoms';
import { ModalWrapper, TextComponent } from 'src/components/common';
import { CREDITS_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useLottieController } from 'src/hooks/useLottiePlayer.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './CustomGraphic.module.scss';

interface DetailsProps {
  backgroundColor?: string;
  line?: boolean;
  copy: string;
}

export const Details: FunctionComponent<DetailsProps> = ({ backgroundColor, line, copy }) => (
  <div className={styles.details}>
    {line ? <div className={styles.line} /> : <div className={styles.circle} style={{ backgroundColor }} />}
    <p className={styles.copy}>{copy}</p>
  </div>
);
interface CustomGraphicProps {
  tagline: string;
  heading: string;
  copy: string;
  description: string;
  lottieAnimation: object;
  lottieAlt: string;
  details: {
    detailFirst: string;
    detailSecond: string;
    detailThird: string;
    detailFourth: string;
    detailFifth: string;
  };
  footerContent: { footerParagraph: string; footerLinkLabel: string; footerLinkHref: string; footerCTA: string };
  modalContent: { modalImage: ImageAttributes; modalTitle: string; modalCopy: string };
}
export const CustomGraphic: FunctionComponent<CustomGraphicProps> = ({
  tagline,
  heading,
  copy,
  description,
  lottieAnimation,
  lottieAlt,
  details,
  footerContent,
  modalContent,
}) => {
  const [creditsBundle] = useBundle(CREDITS_BUNDLE_NAME);

  const { lottieRef, lottieWrapperRef, isPlaying, togglePlayPause, onLoopComplete } = useLottieController();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const firstDetailCircle = '#DFEBEE';
  const secondtDetailCircle = '#91DCFA';
  const thirdDetailCircle = '#ADEDC4';
  const fourthtDetailCircle = '#D9F4E8';

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={styles.customGraphic}>
      <div className={styles.heading}>
        <Tagline className={styles.tagline}>{tagline}</Tagline>
        <TextComponent headingSize="h3" theme={SECTION_THEME.Light} heading={heading} copy={copy} />
      </div>
      <div className={styles.descriptionAndButtonWrapper}>
        <p className={styles.description}>{description}</p>
        <button
          data-testid="pauseButton"
          className={styles.pauseButton}
          onClick={togglePlayPause}
          type="button"
          aria-label={
            isPlaying
              ? t(creditsBundle, 'creditsPage_customGraphic_lottie_pause')
              : t(creditsBundle, 'creditsPage_customGraphic_lottie_play')
          }
        >
          <Icon name={isPlaying ? 'pause' : 'play'} className={styles.pauseIcon} />
        </button>
      </div>
      <div className={styles.lottieWrapper} ref={lottieWrapperRef}>
        <Lottie
          animationData={lottieAnimation}
          lottieRef={lottieRef}
          onComplete={onLoopComplete}
          autoPlay={false}
          loop={false}
          alt={lottieAlt}
        />
      </div>
      <div className={styles.detailsContent}>
        <div className={styles.detailsWrapper}>
          <Details backgroundColor={firstDetailCircle} copy={details.detailFirst} />
          <Details backgroundColor={secondtDetailCircle} copy={details.detailSecond} />
          <Details backgroundColor={thirdDetailCircle} copy={details.detailThird} />
          <Details backgroundColor={fourthtDetailCircle} copy={details.detailFourth} />
          <Details line copy={details.detailFifth} />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLinkWrapper}>
          <p className={styles.footerParagraph}>
            {footerContent.footerParagraph}{' '}
            <FormattedLink
              className={styles.footerLink}
              text={footerContent.footerLinkLabel}
              link={footerContent.footerLinkHref}
            />
          </p>
        </div>
        <PrimaryButtonV2
          label={footerContent.footerCTA}
          icon="plus"
          variant={PRIMARY_BUTTON_V2_VARIANT.Secondary}
          className={styles.footerButton}
          onClick={handleModalOpen}
          aria-label={t(creditsBundle, 'creditsPage_customGraphic_footer_cta_ariaLabel')}
          data-testid="modalButton"
        />
      </div>
      <ModalWrapper isVisible={isModalVisible} close={handleModalClose}>
        <div className={styles.modalContent}>
          <div className={styles.modalUpperCopy}>
            <AsxImage
              src={modalContent.modalImage.src}
              alt={modalContent.modalImage.alt}
              className={styles.modalImage}
              componentName="CustomGraphic"
            />
            <Tagline className={styles.modalTagline}>
              {t(creditsBundle, 'creditsPage_customGraphic_footer_cta')}
            </Tagline>
            <h4>{modalContent.modalTitle}</h4>
            <p>{modalContent.modalCopy}</p>
          </div>
          <div className={styles.footerLinkWrapper}>
            <p className={styles.footerParagraph}>{footerContent.footerParagraph}</p>{' '}
            <FormattedLink
              className={styles.footerLink}
              text={footerContent.footerLinkLabel}
              link={footerContent.footerLinkHref}
            />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};
