import { ComponentProps, type ReactElement } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Tagline } from 'src/components/atoms';
import { RedirectionCard } from 'src/components/common';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { shouldRenderDeborahLandingPage, t } from 'src/helpers';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './PrimaryHero.module.scss';

type PrimaryHeroProps = {
  /*
   * String - Label to display above the heading.
   */
  label?: string;

  /*
   * String - Main heading text for the hero component.
   */
  heading: string;

  /*
   * String - Description text for additional details.
   */
  description?: string;

  /*
   * Image - Image object to display in the hero component.
   */
  image: ImageAttributes;

  /*
   * Object - Optional clickable redirection card
   */
  card?: ComponentProps<typeof RedirectionCard>;
};

export function PrimaryHero({ label, heading, image, description, card }: PrimaryHeroProps): ReactElement {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  return (
    <div className={styles.PrimaryHero} style={{ backgroundImage: `url(${image.src})` }}>
      <div className={styles.mainBox}>
        <div className={styles.mainCard}>
          {label && <Tagline className={styles.tagline}>{label}</Tagline>}
          <h2 className={styles.heading}>{heading}</h2>
          {description && (
            <div className={styles.cardFooter}>
              <p className={styles.label}>{description}</p>
            </div>
          )}
        </div>
        {card && shouldRenderDeborahLandingPage() && <RedirectionCard className={styles.redirectionCard} {...card} />}
      </div>

      <p className={styles.scrollDownLabel}>{t(commonBundle, 'primaryHeroScroll')}</p>
    </div>
  );
}
