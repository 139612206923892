import moment from 'moment';

import {
  AsxFormContentType,
  AsxFormTopic,
  AsxFormType,
  PageIngress,
  RelationToAmazon,
} from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';

import { FormStrings } from 'src/constants';

export const relationToAmazonTranslationMap: Record<RelationToAmazon, string> = {
  [RelationToAmazon.SUPPLIER]: FormStrings.SUPPLIER_LABEL,
  [RelationToAmazon.AWS_CUSTOMER]: FormStrings.AWS_CUSTOMER_LABEL,
  [RelationToAmazon.VENDOR]: FormStrings.VENDOR_LABEL,
  [RelationToAmazon.SELLING_PARTNER]: FormStrings.SELLING_PARTNER_LABEL,
  [RelationToAmazon.THE_CLIMATE_PLEDGE_SIGNATORY]: FormStrings.TCP_SIGNATORY_LABEL,
  [RelationToAmazon.AMAZON_CUSTOMER]: FormStrings.AMAZON_CUSTOMER_LABEL,
  [RelationToAmazon.OTHER]: FormStrings.OTHER_LABEL,
};

export const relationToAmazonDescriptionTranslationMap: Record<RelationToAmazon, string> = {
  [RelationToAmazon.SUPPLIER]: FormStrings.SUPPLIER_DESCRIPTION,
  [RelationToAmazon.AWS_CUSTOMER]: FormStrings.AWS_CUSTOMER_DESCRIPTION,
  [RelationToAmazon.VENDOR]: FormStrings.VENDOR_DESCRIPTION,
  [RelationToAmazon.SELLING_PARTNER]: FormStrings.SELLING_PARTNER_DESCRIPTION,
  [RelationToAmazon.THE_CLIMATE_PLEDGE_SIGNATORY]: FormStrings.TCP_SIGNATORY_DESCRIPTION,
  [RelationToAmazon.AMAZON_CUSTOMER]: FormStrings.AMAZON_CUSTOMER_DESCRIPTION,
  [RelationToAmazon.OTHER]: '',
};

export const asxFormTopicTranslationMap: Record<AsxFormTopic, string> = {
  [AsxFormTopic.MEASUREMENT]: FormStrings.MEASUREMENT_LABEL,
  [AsxFormTopic.CARBON_FREE_ENERGY]: FormStrings.CARBON_FREE_ENERGY_LABEL,
  [AsxFormTopic.WASTE_AND_CIRCULARITY]: FormStrings.WASTE_AND_CIRCULARITY_LABEL,
  [AsxFormTopic.GETTING_STARTED]: FormStrings.GETTING_STARTED_LABEL,
  [AsxFormTopic.WATER_STEWARDSHIP]: FormStrings.WATER_STEWARDSHIP_LABEL,
  [AsxFormTopic.BUILDINGS]: FormStrings.BUILDINGS_LABEL,
  [AsxFormTopic.CARBON_NEUTRALIZATION]: FormStrings.CARBON_NEUTRALIZATION_LABEL,
  [AsxFormTopic.HUMAN_RIGHTS]: FormStrings.HUMAN_RIGHTS_LABEL,
  [AsxFormTopic.THE_SCIENCE_EXCHANGE]: FormStrings.THE_SCIENCE_EXCHANGE_LABEL,
  [AsxFormTopic.TRANSPORTATION]: FormStrings.TRANSPORTATION_LABEL,
  [AsxFormTopic.OTHER]: FormStrings.OTHER_LABEL,
};

export const asxFormTypeTranslationMap: Record<AsxFormType, string> = {
  [AsxFormType.GENERAL_CONTENT_INTAKE]: FormStrings.GENERAL_CONTENT_INTAKE_LABEL,
  [AsxFormType.GENERAL_FEEDBACK]: FormStrings.GENERAL_FEEDBACK_LABEL,
};

export const asxFormContentTypeTranslationMap: Record<AsxFormContentType, string> = {
  [AsxFormContentType.TOOL]: FormStrings.TOOL_LABEL,
  [AsxFormContentType.RESOURCE]: FormStrings.RESOURCE_LABEL,
  [AsxFormContentType.CASE_STUDY]: FormStrings.CASE_STUDY_LABEL,
  [AsxFormContentType.OTHER]: FormStrings.OTHER_LABEL,
};

export const pageIngressToTopic: Partial<Record<PageIngress, AsxFormTopic>> = {
  [PageIngress.CARBON_FREE_ENERGY]: AsxFormTopic.CARBON_FREE_ENERGY,
  [PageIngress.MEASUREMENT]: AsxFormTopic.MEASUREMENT,
  [PageIngress.BUILDINGS]: AsxFormTopic.BUILDINGS,
  [PageIngress.CARBON_NEUTRALIZATION]: AsxFormTopic.CARBON_NEUTRALIZATION,
  [PageIngress.HUMAN_RIGHTS]: AsxFormTopic.HUMAN_RIGHTS,
  [PageIngress.TRANSPORTATION]: AsxFormTopic.TRANSPORTATION,
  [PageIngress.SCIENCE_EXCHANGE]: AsxFormTopic.THE_SCIENCE_EXCHANGE,
  [PageIngress.WATER_STEWARDSHIP]: AsxFormTopic.WATER_STEWARDSHIP,
  [PageIngress.WASTE_AND_CIRCULARITY]: AsxFormTopic.WASTE_AND_CIRCULARITY,
  [PageIngress.GETTING_STARTED]: AsxFormTopic.GETTING_STARTED,
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const urlRegex = /^www\.[^\s@]+\.[^\s@]+$/;
export const yearRegex = /^\d{4}$/;
export const monthYearRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;

export const shortInputMaxLength = 300;
export const longInputMaxLength = 1000;

export const validateEmptyTextInput = (inputValue: string, output: string) => !!inputValue.trim() || output;

export const validateMonthYearDateInput = (input: string, output: string) => {
  const inputDate = moment(input, 'MM/YYYY');
  const currentDate = moment();
  return currentDate.isAfter(inputDate, 'month') ? output : undefined;
};
