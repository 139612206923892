import classnames, { Argument } from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { CTA_CLASS } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './PrimaryButtonV2.module.scss';

export enum PRIMARY_BUTTON_V2_SIZE {
  Regular = 'regularSize',
  Small = 'smallSize',
  Wide = 'wideSize',
}

export enum PRIMARY_BUTTON_V2_VARIANT {
  Default = 'default',
  Inverted = 'inverted',
  Outline = 'outline',
  Rate = 'rate',
  Secondary = 'secondary',
  SecondaryNoIcon = 'secondaryNoIcon',
}

export enum PRIMARY_BUTTON_V2_ICON_POSITION {
  After = 'after',
  Before = 'before',
}

type PrimaryButtonV2Props = (
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | React.ButtonHTMLAttributes<HTMLButtonElement>
) & {
  // TODO add documentation comments for every prop
  /**
   * String label displayed in button
   */
  label?: string;
  isBold?: boolean;
  /**
   * Optional icon to display in the button
   */
  icon?: IconType;
  highlightedIcon?: string;
  buttonSize?: PRIMARY_BUTTON_V2_SIZE;
  /**
   * Optional display variant for the button
   */
  variant?: PRIMARY_BUTTON_V2_VARIANT;
  /**
   * Optional theme style to apply to the button
   */
  theme?: SECTION_THEME;
  className?: Argument;
  /**
   * Optional position string for icon displayed in the button
   */
  iconPosition?: PRIMARY_BUTTON_V2_ICON_POSITION;
  /**
   * Optional argument to add a class to the content of the button, it's different from className because this one targets the
   * inner div where the content/copy is, rather than targeting the outer anchor like className does
   */
  contentClassName?: Argument;
  /**
   * For the cards we need to remove animation
   */
  hasHoverAnimation?: boolean;
  /**
   * Optional link to open on button click
   */
  link?: string;
  /**
   * Optional query strings to add to the link
   */
  queryStrings?: Record<string, string>;
  /**
   * Optional additional test id
   */
  testId?: string;
  /**
   * Optional boolean to add hover animation on mobile
   */
  hasMobileHover?: boolean;
};

const themeToStyle: Partial<Record<SECTION_THEME, Argument>> = {
  [SECTION_THEME.Light]: styles.isLight,
  [SECTION_THEME.Dark]: styles.isDark,
  [SECTION_THEME.Green_Primary]: styles.isGreenPrimary,
  [SECTION_THEME.Teal]: styles.isTeal,
  [SECTION_THEME.Gray]: styles.isGray,
};

/**
 * Main button element to be used on ASX. Supports themes and variations for different rendering styles.
 */
export const PrimaryButtonV2: FC<PrimaryButtonV2Props> = ({
  label,
  isBold,
  icon,
  variant = PRIMARY_BUTTON_V2_VARIANT.Default,
  theme = SECTION_THEME.Light,
  buttonSize = PRIMARY_BUTTON_V2_SIZE.Regular,
  className,
  link,
  queryStrings,
  iconPosition = PRIMARY_BUTTON_V2_ICON_POSITION.After,
  contentClassName,
  hasHoverAnimation = true,
  testId,
  hasMobileHover,
  ...props
}) => {
  const { isSmallDesktop } = useDeviceSize();

  const commonProps = {
    className: classnames(
      className,
      styles.primaryButton,
      styles[variant],
      themeToStyle[theme],
      !label && styles.isOnlyIcon,
      styles[buttonSize],
      isBold && styles.bold,
      icon && label && styles.icon,
      hasHoverAnimation && styles.hasHoverAnimation,
      !isSmallDesktop && hasMobileHover && styles.hasMobileHover
    ),
  };

  const labelClasses = classnames(styles.label, CTA_CLASS);

  const content = (
    <div className={styles.buttonContent}>
      <div
        className={classnames(
          styles.primaryContent,
          iconPosition === PRIMARY_BUTTON_V2_ICON_POSITION.Before && styles.reversed,
          !isSmallDesktop && hasMobileHover && styles.mobileHover,
          contentClassName
        )}
      >
        {label && <span className={labelClasses}>{label}</span>}
        {icon && <Icon name={icon} className={styles.icon} />}
      </div>
      <div
        aria-hidden
        className={classnames(
          styles.secondaryContent,
          iconPosition === PRIMARY_BUTTON_V2_ICON_POSITION.Before && styles.reversed,
          !isSmallDesktop && hasMobileHover && styles.mobileHover,
          contentClassName
        )}
      >
        {label && <span className={labelClasses}>{label}</span>}
        {icon && <Icon name={icon} className={styles.icon} />}
      </div>
    </div>
  );

  if (link) {
    // TODO: Refactor this into a "SmartLink" wrapper element which uses the correct native or react-router link
    const relativeLink = link.startsWith('/');
    const formattedLink = queryStrings ? `${link}?${new URLSearchParams(queryStrings).toString()}` : link;
    return !relativeLink ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        data-testid={`primaryButton ${testId}`}
        href={formattedLink}
        {...commonProps}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {content}
      </a>
    ) : (
      <Link
        data-testid={`primaryButton ${testId}`}
        to={formattedLink}
        {...commonProps}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      type="button"
      data-testid={testId}
      {...commonProps}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {content}
    </button>
  );
};
