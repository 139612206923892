import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useFormContext, UseFormReturn, FieldError } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { PRIMARY_BUTTON_V2_SIZE, PrimaryButtonV2 } from 'src/components/atoms';
import { SuccessCheckmark } from 'src/components/atoms/success-checkmark/SuccessCheckmark';
import styles from 'src/components/common/modal/content/hmd/ModalHmdContent.module.scss';
import { useRatingOptions } from 'src/components/common/modal/content/hmd/useRatingOptions';
import { CheckboxDescriptionGroup } from 'src/components/form-elements';
import { RadioGroup } from 'src/components/form-elements/radio-group/RadioGroup';
import { HmdMetadata, STATE } from 'src/components/templates/hmd/HmdModal';
import {
  COMMON_BUNDLE_NAME,
  HMD_RESOURCE_BUNDLE_NAME,
  SUBMITTING_ARIA_LABEL,
  HMDFormStrings,
  FormStrings,
  CONTACT_FORM_PAGE_BUNDLE_NAME,
  PAGE_INGRESS_BY_ROUTE,
  HMD_RATING_OPTIONS,
} from 'src/constants';
import { t } from 'src/helpers';
import { logError } from 'src/logger';
import { INGRESS_PAGE, RESOURCE_ID, RESOURCE_TITLE } from 'src/logging-helpers/metrics-constants';
import { hmdConnectPublisher } from 'src/logging-helpers/metrics-helpers';

export enum HMD_FORM_KEYS {
  RATING = 'rating',
  COMMENT = 'comment',
}

export type HmdFormValues = {
  [HMD_FORM_KEYS.RATING]: HMD_RATING_OPTIONS;
  [HMD_FORM_KEYS.COMMENT]: string[];
};

type ModalHmdContentProps = {
  close: () => void;
  methods: UseFormReturn<HmdFormValues>;
  state?: STATE;
  setState: Dispatch<SetStateAction<STATE>>;
  metadata: HmdMetadata;
};

const HMD_FORM_ID = 'hmdForm';

/**
 * HMD Content component for the ModalWrapper.tsx.
 */
export const ModalHmdContent: FC<ModalHmdContentProps> = ({ methods, state, setState, close, metadata }) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    watch,
    resetField,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);
  const [formBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);
  const [hmdBundle] = useBundle(HMD_RESOURCE_BUNDLE_NAME);

  const hasErrorState = state === STATE.error;
  const location = useLocation();

  const reasonsQuestionRef = useRef<HTMLDivElement>(null);
  const ratingValue = watch(HMD_FORM_KEYS.RATING);
  const currentOptions = useRatingOptions(ratingValue, hmdBundle) ?? [];

  useEffect(() => {
    if (ratingValue && reasonsQuestionRef.current) {
      reasonsQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ratingValue]);

  const handleFormSubmit = (values: HmdFormValues) => {
    setState(STATE.busy);
    const { comment, rating } = values;
    try {
      const pageIngress = PAGE_INGRESS_BY_ROUTE[location.pathname].toString();
      hmdConnectPublisher(
        {
          rating,
          ...(comment && comment.length > 0 && { comment: comment.join(',') }),
        },
        // publish custom HMD metrics
        (publisher) => {
          publisher.publishStringTruncate(INGRESS_PAGE, pageIngress);
          publisher.publishStringTruncate(RESOURCE_ID, metadata.resourceId);
          if (metadata.resourceTitle) {
            publisher.publishStringTruncate(RESOURCE_TITLE, metadata.resourceTitle);
          }
        }
      );
      setState(STATE.success);
    } catch (err: any) {
      logError('modalHmdContent', 'Error submitting HMD form data', err);
      setState(STATE.error);
    }
  };

  const handleRetry = () => {
    setState(STATE.normal);
  };

  if (hasErrorState) {
    return (
      <div className={styles.contentContainer}>
        <h5>
          {t(hmdBundle, HMDFormStrings.OOPS_LABEL)}
          <br />
          {t(hmdBundle, HMDFormStrings.SOMETHING_WENT_WRONG_LABEL)}
        </h5>
        <p>{t(hmdBundle, HMDFormStrings.TRY_LATER_LABEL)}</p>
        <PrimaryButtonV2
          contentClassName={styles.primaryButton}
          label={t(formBundle, FormStrings.TRY_AGAIN_LABEL)}
          icon="chevronRight"
          type="button"
          data-testid="try-again-button"
          buttonSize={PRIMARY_BUTTON_V2_SIZE.Small}
          onClick={handleRetry}
          hasMobileHover
        />
      </div>
    );
  }

  if (state === STATE.success) {
    return (
      <div className={styles.contentContainer}>
        <SuccessCheckmark />
        <p>{t(hmdBundle, HMDFormStrings.FEEDBACK_THANKS_LABEL)}</p>
        <h5>{t(hmdBundle, HMDFormStrings.FEEDBACK_HELPS_LABEL)}</h5>
        <PrimaryButtonV2
          contentClassName={styles.primaryButton}
          label={t(hmdBundle, HMDFormStrings.RETURN_TO_EXCHANGE_LABEL)}
          icon="chevronRight"
          type="button"
          data-testid="go-back-button"
          buttonSize={PRIMARY_BUTTON_V2_SIZE.Small}
          onClick={close}
          hasMobileHover
        />
      </div>
    );
  }

  return (
    <>
      <div>
        <p className={styles.formRequiredFields}>{t(formBundle, FormStrings.REQUIRED_FIELDS_LABEL)}</p>
      </div>
      <div className={styles.formContainer}>
        <form
          data-testid={HMD_FORM_ID}
          id={`${HMD_FORM_ID}-${metadata.resourceId}`}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className={styles.ratingQuestionContainer}>
            <RadioGroup
              className={styles.hmdRadio}
              formId={`${metadata.resourceId}`}
              ariaId="hmdRadioButtons"
              title={t(hmdBundle, HMDFormStrings.HOW_SATISFIED_Q_LABEL)}
              options={[
                {
                  value: HMD_RATING_OPTIONS.RATING_1,
                  label: HMD_RATING_OPTIONS.RATING_1,
                  additionalLabel: t(hmdBundle, HMDFormStrings.VERY_UNSATIFIED_LABEL),
                },
                { value: HMD_RATING_OPTIONS.RATING_2, label: HMD_RATING_OPTIONS.RATING_2 },
                {
                  value: HMD_RATING_OPTIONS.RATING_3,
                  label: HMD_RATING_OPTIONS.RATING_3,
                  additionalLabel: t(hmdBundle, HMDFormStrings.NEUTRAL_LABEL),
                },
                { value: HMD_RATING_OPTIONS.RATING_4, label: HMD_RATING_OPTIONS.RATING_4 },
                {
                  value: HMD_RATING_OPTIONS.RATING_5,
                  label: HMD_RATING_OPTIONS.RATING_5,
                  additionalLabel: t(hmdBundle, HMDFormStrings.VERY_SATISFIED_LABEL),
                },
              ]}
              name={HMD_FORM_KEYS.RATING}
              control={control}
              onChange={() => {
                clearErrors();
                resetField(HMD_FORM_KEYS.COMMENT);
                setValue(HMD_FORM_KEYS.COMMENT, []);
              }}
              required={t(formBundle, FormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL)}
            />
          </div>
          {ratingValue && (
            <div className={styles.reasonQuestionContainer} ref={reasonsQuestionRef}>
              <CheckboxDescriptionGroup
                variant="modal"
                optionsPrefix={t(hmdBundle, HMDFormStrings.OPTIONS_PREFIX)}
                key={metadata.resourceId}
                id={`${metadata.resourceId}-${HMD_FORM_KEYS.COMMENT}`}
                testId={`${metadata.resourceId}-${HMD_FORM_KEYS.COMMENT}-test`}
                onChange={(option: string, isChecked) => {
                  const currentSelection = getValues(HMD_FORM_KEYS.COMMENT) ?? [];
                  if (isChecked) {
                    setValue(HMD_FORM_KEYS.COMMENT, [...currentSelection, option]);
                  } else {
                    setValue(
                      HMD_FORM_KEYS.COMMENT,
                      currentSelection.filter((opt: string) => opt !== option)
                    );
                  }
                  clearErrors(HMD_FORM_KEYS.COMMENT);
                }}
                label={t(hmdBundle, HMDFormStrings.RATING_REASON_Q_LABEL)}
                options={currentOptions}
                errors={errors[HMD_FORM_KEYS.COMMENT] as FieldError}
                values={watch(HMD_FORM_KEYS.COMMENT) ?? []}
              />
            </div>
          )}
        </form>
      </div>

      {state === STATE.busy && (
        <div role="status">
          <KatProgress
            katAriaLabel={t(commonBundle, SUBMITTING_ARIA_LABEL)}
            indeterminate
            size="medium"
            type="circular"
          />
        </div>
      )}
    </>
  );
};

type HmdSubmitButtonProps = {
  resourceId: string;
};

export const HmdSubmitButton: FC<HmdSubmitButtonProps> = ({ resourceId }) => {
  const [formBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);
  const { watch } = useFormContext<HmdFormValues>();
  const ratingValue = watch(HMD_FORM_KEYS.RATING);

  return (
    <PrimaryButtonV2
      contentClassName={styles.primaryButton}
      label={t(formBundle, FormStrings.FORM_SUBMIT_BUTTON_LABEL)}
      icon="chevronRight"
      type="submit"
      data-testid="submit-button"
      form={`${HMD_FORM_ID}-${resourceId}`}
      buttonSize={PRIMARY_BUTTON_V2_SIZE.Small}
      disabled={!ratingValue}
      hasMobileHover
    />
  );
};
