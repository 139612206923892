import classNames from 'classnames';
import React from 'react';
import Markdown from 'react-markdown';

import { Icon } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './AsxMarkdownContent.module.scss';

interface AsxMarkdownContentProps {
  copy: string;
  className?: string;
  // tabIndex for being able to bypass focus if markdown lives inside a closed accordion
  tabIndex?: number;
  theme?: SECTION_THEME;
}

export const AsxMarkdownContent: React.FC<AsxMarkdownContentProps> = ({
  copy,
  className,
  tabIndex = 0,
  theme = SECTION_THEME.Light,
}) => {
  // Custom render function for links
  const renderLink = (props: any) => {
    const { children } = props;

    return (
      <a className={styles[theme]} {...props} tabIndex={tabIndex} target="_blank" rel="noreferrer">
        {children}
        <Icon className={styles.icon} name="chevronTopRight" />
      </a>
    );
  };

  return (
    <Markdown
      className={classNames(styles['markdown-copy'], className)}
      components={{ a: renderLink }} // Use custom link rendering
    >
      {copy}
    </Markdown>
  );
};
