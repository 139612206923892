import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

import { Heading, HEADING_SIZE, Tagline } from 'src/components/atoms';
import { BentoCard, Slider, Tooltip } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { TooltipAttributes } from 'src/interfaces/CommonAttributes';

import styles from './BentoCards.module.scss';

interface BentoCardsProps {
  description: string;
  label: string;
  title: string;
  tooltipTheme?: SECTION_THEME;
  tooltipAttributes?: TooltipAttributes[];
  firstCard: {
    label: string;
    title: string;
  };
  secondCard: {
    label: string;
    title: string;
  };
}

const CardsWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { isSmallDesktop } = useDeviceSize();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isSmallDesktop && children) return <>{children}</>;

  return <Slider>{children}</Slider>;
};

export const BentoCards: FunctionComponent<BentoCardsProps> = ({
  description,
  label,
  title,
  tooltipTheme = SECTION_THEME.Light,
  tooltipAttributes,
  firstCard,
  secondCard,
}) => (
  <div className={styles.bentoCards}>
    <div className={classNames(styles.textContent, styles.gridElement)}>
      <Tagline className={styles.tagline}>{label}</Tagline>
      <Heading className={styles.heading} as="h3" size={HEADING_SIZE.h3}>
        {title}
      </Heading>
      <p className={styles.description}>
        <Tooltip copy={description} tooltipAttributes={tooltipAttributes} tooltipTheme={tooltipTheme} />
      </p>
    </div>
    <CardsWrapper>
      <BentoCard className={classNames(styles.card, styles.gridElement)} label={firstCard.label}>
        <Heading className={styles.cardHeading} as="h3" size={HEADING_SIZE.h3}>
          {firstCard.title}
        </Heading>
      </BentoCard>

      <BentoCard
        className={classNames(styles.card, styles.gridElement)}
        label={secondCard.label}
        theme={SECTION_THEME.Dark}
      >
        <Heading className={styles.cardHeading} as="h3" size={HEADING_SIZE.h3}>
          {secondCard.title}
        </Heading>
      </BentoCard>
    </CardsWrapper>
  </div>
);
