import { useInView } from 'framer-motion';
import { LottieRefCurrentProps } from 'lottie-react';
import { useRef, useState, useEffect } from 'react';

export const useLottieController = () => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const lottieWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loopEnded, setLoopEnded] = useState(false);
  const [wasWatchedOnce, setWasWatchedOnce] = useState(false);

  const lottieIsInView = useInView(lottieWrapperRef, { amount: 0.9 });

  const togglePlayPause = () => {
    if (!lottieRef.current) return;
    if (isPlaying) {
      lottieRef.current.pause();
      setLoopEnded(false);
    } else if (loopEnded) {
      lottieRef.current.goToAndPlay(0);
    } else {
      lottieRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const onLoopComplete = () => {
    if (!lottieRef.current) return;
    const duration = lottieRef.current.getDuration(true);
    if (!wasWatchedOnce) {
      setWasWatchedOnce(true);
    }
    if (duration) {
      lottieRef.current.goToAndStop(duration - 1, true);
      setIsPlaying(false);
      setLoopEnded(true);
    }
  };

  useEffect(() => {
    if (!lottieRef.current || !lottieWrapperRef.current) return;
    if (!wasWatchedOnce && lottieIsInView) {
      lottieRef.current.play();
      setIsPlaying(true);
    }
  }, [wasWatchedOnce, lottieIsInView, lottieRef, lottieWrapperRef]);

  useEffect(() => {
    if (!lottieRef.current) return;
    lottieRef.current.pause();
  }, []);

  return {
    lottieRef,
    lottieWrapperRef,
    isPlaying,
    togglePlayPause,
    onLoopComplete,
    wasWatchedOnce,
  };
};
