import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive';

import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { AsxImage, PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms';
import { SUBTITLE_CLASS } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './SecondaryBanner.module.scss';

interface SecondaryBannerProps {
  imagePrimary: ImageAttributes;
  imageSecondary: ImageAttributes;
  title: string;
  subTitle: string;
  description: string;
  theme: SECTION_THEME;
  cta: {
    label: string;
    link: string;
  };
}

export const SecondaryBanner: FunctionComponent<SecondaryBannerProps> = ({
  imagePrimary,
  imageSecondary,
  title,
  subTitle,
  description,
  theme,
  cta,
}) => {
  // TODO: Replace on follow-up CR with Odin's new useDeviceSize hook
  const isSmallDesktop = useMediaQuery({ query: `(min-width: ${variables.smallDesktop})` });

  return (
    <div className={classNames(styles.bannerContainer, theme === 'dark' && styles.isDark)}>
      <div className={styles.ctaContainer}>
        <div className={styles.copyContainer}>
          <h2 className={styles.title}>{title}</h2>
          <h5 className={styles.subTitle}>{subTitle}</h5>
          <p className={classNames(styles.description, SUBTITLE_CLASS)}>{description}</p>
        </div>
        <PrimaryButton
          className={styles.ctaButton}
          label={cta.label}
          icon="chevronRight"
          theme={theme}
          link={cta.link}
          buttonSize={isSmallDesktop ? PRIMARY_BUTTON_SIZE.Regular : PRIMARY_BUTTON_SIZE.Small}
        />
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.imagePrimaryWrapper}>
          <AsxImage
            className={classNames(styles.image, styles.imagePrimary)}
            src={imagePrimary.src}
            alt={imagePrimary.alt}
            componentName="SecondaryBanner"
          />
        </div>
        <div className={styles.imageSecondaryWrapper}>
          <AsxImage
            className={classNames(styles.image, styles.imageSecondary)}
            src={imageSecondary.src}
            alt={imageSecondary.alt}
            componentName="SecondaryBanner"
          />
        </div>
      </div>
    </div>
  );
};
