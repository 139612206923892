import classNames from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import { AsxImage, Icon } from 'src/components/atoms';
import { NavigationTabsProps } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import commonStyles from 'src/components/atoms/navigation-tabs/NavigationTabs.module.scss';
import { ModalWrapper } from 'src/components/common';
import { useHeaderVisibility } from 'src/hooks/useHeaderVisibility';

import styles from './NavigationTabsMobile.module.scss';

const MOBILE_NAVBAR_HEIGHT = 72;
const TOP_INTERSECTION_TRIGGER = 2 * MOBILE_NAVBAR_HEIGHT + 2;

interface NavigationTabsMobileProps extends NavigationTabsProps {
  /*
     String title that will be shown when the mobile dropdown modal is opened
    */
  modalTitle: string;
}

export const NavigationTabsMobile: FunctionComponent<NavigationTabsMobileProps> = ({
  activeTab,
  modalTitle,
  tabs,
  onTabChange,
}) => {
  const [isExtended, setIsExtended] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const self = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const activeTabContent = tabs[activeTab];
  const { isHeaderVisible } = useHeaderVisibility();

  const handleOpenMenu = () => setIsOpened(true);
  const handleCloseMenu = () => setIsOpened(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsExtended(!entry.isIntersecting && entry.boundingClientRect.top < window.innerHeight),
      {
        rootMargin: `-${TOP_INTERSECTION_TRIGGER}px 0px 0px 0px`,
        threshold: 0,
      }
    );

    observer.observe(self.current);

    return () => observer.disconnect();
  }, [self]);

  return (
    <>
      <button
        ref={self}
        className={classNames(
          styles.navigationTabsStickyContainer,
          commonStyles.navigationTabsStickyContainer,
          isHeaderVisible && commonStyles.withNavbar,
          isExtended && styles.extended
        )}
        type="button"
        onClick={handleOpenMenu}
        data-testid="navigation-tab"
      >
        <div className={classNames(commonStyles.navigationTabs, styles.navigationTabs)}>
          <div className={classNames(commonStyles.tab, styles.tab)}>
            <AsxImage className={commonStyles.image} {...activeTabContent.image} componentName="NavigationTabsMobile" />
            <p className={commonStyles.label}>{activeTabContent.label}</p>
            <Icon className={styles.icon} name="chevronDown" />
          </div>
        </div>
      </button>
      <ModalWrapper title={modalTitle} isVisible={isOpened} close={handleCloseMenu}>
        <div role="radiogroup" aria-labelledby="navigation-tabs-mobile-title">
          <p className="accesibilityHelper" id="navigation-tabs-mobile-title">
            {modalTitle}
          </p>
          <div>
            {tabs.map((option, index) => (
              <label className={styles.option} key={option.label} htmlFor={option.label}>
                <AsxImage className={commonStyles.image} {...option.image} componentName="NavigationTabsMobile" />
                <input
                  id={option.label}
                  className={styles.radio}
                  type="radio"
                  name="radio-button"
                  data-testid="option"
                  onChange={() => onTabChange(index)}
                  checked={index === activeTab}
                />
                <p className={styles.label}>{option.label}</p>
                <div className={styles.circle}>
                  <Icon name="check" className={styles.checkIcon} />
                </div>
              </label>
            ))}
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};
