import classNames from 'classnames';
import { createElement, ReactElement, useState } from 'react';

import { KatCard } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';
import base from '@amzn/sss-website-theme/dist/theme/base.module.scss';

import { PrimaryButtonV2, PRIMARY_BUTTON_V2_SIZE } from 'src/components/atoms';
import { AsxImage } from 'src/components/atoms/asx-image/AsxImage';
import { IconType } from 'src/components/atoms/icons/icons';
import { RateButton } from 'src/components/atoms/rate-button/RateButton';
import { HmdModal } from 'src/components/templates/hmd/HmdModal';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderHmd, t } from 'src/helpers';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Card.module.scss';

interface CardLink {
  label: string;
  url: string;
}

export interface CardProps {
  rateable?: boolean;
  resourceId?: string;
  title?: string;
  subtitle?: string;
  image?: ImageAttributes;
  pill?: string;
  details?: string;
  link?: CardLink;
  icon?: IconType;
  theme?: SECTION_THEME;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  imagePosition?: 'center' | 'right';
  openInSameTab?: boolean;
  skipAccessibleLabeling?: boolean;
}

export function Card({
  title,
  subtitle,
  link,
  image,
  pill,
  details,
  icon,
  theme = SECTION_THEME.Light,
  headingSize = 'h3',
  imagePosition = 'center',
  openInSameTab,
  skipAccessibleLabeling,
  resourceId,
  rateable,
}: CardProps): ReactElement {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const HeadingTag = createElement(headingSize, { className: classNames(styles[theme], styles.cardHeading) }, title);

  const handleClick = () => {
    if (link?.url) {
      if (openInSameTab) {
        window.location.href = link.url;
      } else {
        window.open(link.url, '_blank');
      }
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // for accessibility reasons we need the link to be the only interactive element but since the whole card is clickable we are going to prevent the clicking of the <a>
    e.preventDefault();
  };

  const handleLinkKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const isRateable = rateable && shouldRenderHmd() && resourceId;

  return (
    <>
      <KatCard
        onClick={!isRateable ? handleClick : undefined}
        className={classNames(styles[theme], styles.card, isRateable && styles.rateable)}
      >
        {(pill ?? details) && (
          <div className={styles.cardHeader} slot="title">
            {pill && <div className={classNames(styles[theme], styles.cardPill)}>{pill}</div>}
            {details && <div className={classNames(styles[theme], styles.details)}>{details}</div>}
          </div>
        )}

        <div className={classNames(styles[theme], styles.cardContent)}>
          <div className={styles.cardText}>
            {HeadingTag}
            <span className={styles.cardSubtitle}> {subtitle} </span>
          </div>
          {isRateable && (
            <div className={styles.rateButtonContainer}>
              <RateButton theme={theme} setIsModalVisible={setIsModalVisible} />
            </div>
          )}

          {image && (
            <AsxImage
              className={styles.cardImage}
              style={{ objectPosition: imagePosition }}
              componentName="Card"
              {...image}
            />
          )}
        </div>
        {createElement(
          isRateable ? 'button' : 'div',
          {
            className: classNames(styles[theme], styles.cardFooter, isRateable && styles.rateable),
            slot: 'footer',
            ...(isRateable && { type: 'button', onClick: handleClick }),
          },
          link && (
            <>
              <a
                className={classNames(styles.link, styles[theme])}
                href={link.url}
                target={openInSameTab ? undefined : '_blank'}
                rel={openInSameTab ? undefined : 'noreferrer'}
                onClick={handleLinkClick}
                onKeyDown={handleLinkKeyDown}
              >
                {link.label}
                {!skipAccessibleLabeling && (
                  <span className={base.visuallyHidden}>
                    {`${t(commonBundle, 'card_about')} ${title}${openInSameTab ? '' : t(commonBundle, 'new_tab')}`}
                  </span>
                )}
              </a>

              <PrimaryButtonV2
                className={styles.cardActionButton}
                icon={icon}
                buttonSize={PRIMARY_BUTTON_V2_SIZE.Small}
                theme={theme}
                hasHoverAnimation={false}
                tabIndex={-1}
                aria-hidden
              />
            </>
          )
        )}
      </KatCard>
      {isRateable && (
        <HmdModal
          key={resourceId}
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
          metadata={{ resourceId, resourceTitle: title }}
        />
      )}
    </>
  );
}
