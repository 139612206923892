import classNames, { Argument } from 'classnames';
import { ReactElement } from 'react';

import { AsxImage, GridSvg } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './GridImage.module.scss';

interface GridImageProps {
  image: ImageAttributes;
  smallImage?: ImageAttributes;
  theme?: SECTION_THEME;
  className?: Argument;
  isFormVariant?: boolean;
}

export function GridImage({
  image,
  smallImage,
  theme = SECTION_THEME.Light,
  className,
  isFormVariant,
}: GridImageProps): ReactElement {
  return (
    <div className={classNames(styles.imagesContainer, isFormVariant && styles.isFormVariant, className)}>
      <GridSvg theme={theme} isFormVariant={isFormVariant} className={styles.gridSvg} data-testid="grid-svg" />
      <AsxImage key={`${image.src}-${image.alt}`} {...image} className={styles.image} componentName="GridImage" />
      {isFormVariant && (
        <AsxImage
          key={`${smallImage?.src}-${smallImage?.alt}`}
          {...smallImage}
          className={styles.smallImage}
          componentName="GridImage"
          data-testid="small-image"
        />
      )}
    </div>
  );
}
